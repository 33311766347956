import Fire from '../Fire';
import firebase from 'firebase';

export async function getTableData(option) {
    const meals = {'Breakfast': [], 'Lunch': [], 'Dinner': [], 'Snack': []};
    let breakfast = await Fire.firestore().collection('HealthyFoodOptions').doc(option).collection('Breakfast').get();
    breakfast.forEach(food => {
        meals['Breakfast'].push(food.data());
    });
    let lunch = await Fire.firestore().collection('HealthyFoodOptions').doc(option).collection('Lunch').get();
    lunch.forEach(food => {
        meals['Lunch'].push(food.data());
    })
    let dinner = await Fire.firestore().collection('HealthyFoodOptions').doc(option).collection('Dinner').get();
    dinner.forEach(food => {
        meals['Dinner'].push(food.data());
    })
    let snack = await Fire.firestore().collection('HealthyFoodOptions').doc(option).collection('Snack').get();
    snack.forEach(food => {
        meals['Snack'].push(food.data());
    })
    return meals;
}

export async function getMealPlan(userToken, meal) {
    const tableData = await Fire.firestore().collection('MealPlan').doc(userToken).collection('Meals').doc(meal).get();
    return tableData.data();
}

export async function getHeader() {
    let doc = await Fire.firestore().collection('HealthyFoodOptions').doc('AdjectiveDay').get();
    if (!doc.exists) {
        throw new Error(`HTTP error! status: ${doc.status}`)
    }
    else {
        return doc.data();
    }
}

export async function getShoppingList(docId) {
    let doc = await Fire.firestore().collection('ShoppingList').doc(docId).get();
    if (!doc.exists) {
        await Fire.firestore().collection('ShoppingList').doc(docId).set({list: []});
        return [];
    }
    else {
        return doc.data().list;
    }
}

export async function addToShoppingList(foodName, docId) {
    return await Fire.firestore().collection('ShoppingList').doc(docId).update({
        list: firebase.firestore.FieldValue.arrayUnion(foodName)
    });
}

export async function removeFromShoppingList(foodName, docId) {
    return await Fire.firestore().collection('ShoppingList').doc(docId).update({
        list: firebase.firestore.FieldValue.arrayRemove(foodName)
    });
}