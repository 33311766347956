import Fire from '../Fire';
import firebase from 'firebase';
import { getTimestamp } from '../../Helpers';

export async function loginUser(email, password) {
    await Fire.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    let res = await Fire.auth().signInWithEmailAndPassword(email, password);
    if (!res) {
        throw new Error(`HTTP error! status: ${res.status}`);
    }
    else {
        return res.user;
    }
}

export async function getUserProfile(user) {
    let res = await Fire.firestore().collection('Users').doc(user.uid).get();
    if (!res) {
        throw new Error(`HTTP error! status: ${res.status}`)
    }
    else {
        return res.data();
    }
}

export async function verifyUsername(username) {
    let res = await Fire.firestore().collection('BNames').doc(username).get();
    if (!res.exists) {
        return true;
    }
    else {
        return false;
    }
}

export async function addNewUsername(user, username) {
    await Fire.firestore().collection('BNames').doc(username).set({date: getTimestamp()});
    return await Fire.firestore().collection('Users').doc(user.uid).update({blogname: username});
}

export async function changeUserPassword(currentPassword, newPassword) {
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
        user.email, 
        currentPassword
    );
    let res = await user.reauthenticateWithCredential(credential)
    
    if (!res) {
        throw new Error(`HTTP error! status: ${res.status}`);
    }
    else {
        return await user.updatePassword(newPassword)
    }
}

export async function getCoachReportForUser(userToken) {
    const res = await Fire.firestore().collection('CoachReports').doc(userToken).collection("WeeklyReports").get()
    let lastApprovedReport;
    res.forEach(report => {
        const tempReport = report.data();
        if (tempReport.approved) {
            lastApprovedReport = tempReport;
        }
    });
    return lastApprovedReport;
}

export async function getNameFromUserToken(userToken) {
    const res = await Fire.firestore().collection('Users').doc(userToken).get()
    return res.data().name;
}

export async function logoutUser() {

    return await Fire.auth().signOut();

}