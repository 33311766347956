import Fire from '../Fire';

export async function addPost(newPost) {

    return await Fire.firestore().collection('Posts').add(newPost);

}

export async function updatePost(postId, newPost) {

    return await Fire.firestore().collection('Posts').doc(postId).set(newPost);

}

export async function removePost(postId) {

    return await Fire.firestore().collection('Posts').doc(postId).delete();

}

export async function getRecentPosts() {

    return await Fire.firestore().collection('Posts')
        .orderBy('timestamp', 'desc').limit(10).get();

}

export async function getPrevPosts(firstPostId) {

    return await Fire.firestore().collection('Posts')
        .orderBy('timestamp', 'desc')
        .endBefore(firstPostId).limit(10).get();

}

export async function getNextPosts(lastPostId) {

    return await Fire.firestore().collection('Posts')
        .orderBy('timestamp', 'desc')
        .startAfter(lastPostId).limit(10).get();

}

export async function getRecentUserPosts(username) {

    return await Fire.firestore().collection('Posts')
        .where('username', '==', username)
        .orderBy('timestamp', 'desc').limit(10).get();

}

export async function getPrevUserPosts(username, firstPostId) {

    return await Fire.firestore().collection('Posts')
        .where('username', '==', username)
        .orderBy('timestamp', 'desc')
        .endBefore(firstPostId).limit(10).get();

}

export async function getNextUserPosts(username, lastPostId) {

    return await Fire.firestore().collection('Posts')
        .where('username', '==', username)
        .orderBy('timestamp', 'desc')
        .startAfter(lastPostId).limit(10).get();

}